

























































import { Component, Prop, Vue } from "vue-property-decorator";

class TestPost {
  constructor(
    private title: string,
    private category: string,
    private date: string,
    private read: string,
    private excerpt: string,
    private author: string,
    private imagePath?: string,
    private tags?: Array<string>,
    private authorImagePath?: string
  ) {}
}

@Component
export default class PreviewCards extends Vue {
  posts = new Array<TestPost>(
    new TestPost(
      "Hipstercat opens first café",
      "Cats",
      "Nov 21, 2020",
      "12 min read",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, distinctio veniam. Voluptatibus quas odit unde numquam explicabo laborum aspernatur earum iusto rerum.",
      "John Hardy",
      require("../assets/examples/cat.jpg"),
      ["coffee", "cake", "bengal cat"],
      require("../assets/examples/john.jpg")
    ),
    new TestPost(
      "Bavarian cars, beautiful as always",
      "Cars",
      "Nov 20, 2020",
      "4 min read",
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus quas odit unde numquam explicabo laborum aspernatur earum iusto rerum, placeat pariatur.",
      "Leah Harvey",
      require("../assets/examples/car.jpg"),
      ["BMW", "Nürburgring", "Design"],
      require("../assets/examples/leah.jpg")
    ),
    new TestPost(
      "It's incredibly fast (because of 5G)",
      "Tech",
      "Nov 19, 2020",
      "9 min read",
      "Voluptatibus quas odit unde numquam explicabo laborum  aspernatur earum iusto rerum, placeat pariatur temporibus nostrum impedit beatae culpa voluptates.",
      "Edith Stewart",
      require("../assets/examples/mac.jpg"),
      ["5G", "M1"],
      require("../assets/examples/edith.jpg")
    )
  );
}
